// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/blog-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tag-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tags-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-feedbacks-tsx": () => import("./../../../src/pages/home/Feedbacks.tsx" /* webpackChunkName: "component---src-pages-home-feedbacks-tsx" */),
  "component---src-pages-home-hero-tsx": () => import("./../../../src/pages/home/Hero.tsx" /* webpackChunkName: "component---src-pages-home-hero-tsx" */),
  "component---src-pages-home-icons-figma-tsx": () => import("./../../../src/pages/home/icons/Figma.tsx" /* webpackChunkName: "component---src-pages-home-icons-figma-tsx" */),
  "component---src-pages-home-icons-icon-jar-tsx": () => import("./../../../src/pages/home/icons/IconJar.tsx" /* webpackChunkName: "component---src-pages-home-icons-icon-jar-tsx" */),
  "component---src-pages-home-icons-illustrator-tsx": () => import("./../../../src/pages/home/icons/Illustrator.tsx" /* webpackChunkName: "component---src-pages-home-icons-illustrator-tsx" */),
  "component---src-pages-home-icons-psd-tsx": () => import("./../../../src/pages/home/icons/PSD.tsx" /* webpackChunkName: "component---src-pages-home-icons-psd-tsx" */),
  "component---src-pages-home-icons-svelte-tsx": () => import("./../../../src/pages/home/icons/Svelte.tsx" /* webpackChunkName: "component---src-pages-home-icons-svelte-tsx" */),
  "component---src-pages-home-icons-vue-tsx": () => import("./../../../src/pages/home/icons/Vue.tsx" /* webpackChunkName: "component---src-pages-home-icons-vue-tsx" */),
  "component---src-pages-home-icons-xd-tsx": () => import("./../../../src/pages/home/icons/XD.tsx" /* webpackChunkName: "component---src-pages-home-icons-xd-tsx" */),
  "component---src-pages-home-integrations-tsx": () => import("./../../../src/pages/home/Integrations.tsx" /* webpackChunkName: "component---src-pages-home-integrations-tsx" */),
  "component---src-pages-home-packs-tsx": () => import("./../../../src/pages/home/Packs.tsx" /* webpackChunkName: "component---src-pages-home-packs-tsx" */)
}

